import React, { useState, useEffect, useRef } from "react"
import { Button, Col, Row, Table } from "react-bootstrap"
import { Modal } from "antd"
import { CheckCircleOutlined } from "@ant-design/icons"
import { useNavigate, useParams } from "react-router-dom"
import Navbar from "../../../Components/Navbar/Navbar"
import {
  dateFormat,
  formatDateSlash,
  formatAmount,
  toastStyle,
  TokenExpiry,
  getType,
  capitalizeFirstLetterOfEachWord,
} from "../../../Helpers/Utils/Common"
import toast from "react-hot-toast"
import { AttachFile } from "@mui/icons-material"
import {
  changeProjectPiStatus,
  getSingleProjectPI,
} from "../../../Helpers/apiCalls/projectApi"
import { UsePostRequest } from "../../../Helpers/hooks/UsePostApiCall"
import PiPdfContent from "../PiPdfContent"
import jsPDF from "jspdf"
import html2canvas from "html2canvas"
// import { saveAs } from "file-saver";

export default function ReviewOperationalExpense() {
  var baseUrl = process.env.REACT_APP_LINK
  const { id } = useParams()
  let navigate = useNavigate()
  const [modal, contextModal] = Modal.useModal()
  const pdfRef = useRef()

  const [inactive, setInactive] = useState(true)
  const [reviewSE, setReviewSE] = useState([])
  const [items, setItems] = useState([])
  const [saving, setSaving] = useState(false)

  async function fetchSingleOperationalExpense() {
    const whtOptionsGoods = [
      { value: "WC158_1", label: "WC158 EWT Goods (1%)" },
      { value: "WI158_1", label: "WI158 EWT Goods (1%)" },
    ]

    const whtOptionsService = [
      { value: "W000_0", label: "0" },
      { value: "WC010_10", label: "WC010 EWT Professionals (10%)" },
      { value: "WC100_5", label: "WC100 EWT Rentals (5%)" },
      { value: "WC120_2", label: "WC120 EWT Services (2%)" },
      { value: "WC160_2", label: "WC160 EWT Services (2%)" },
      { value: "WI010_5", label: "WI010 EWT Professionals (5%)" },
      { value: "WI100_5", label: "WI100 EWT Rentals (5%)" },
      { value: "WI160_2", label: "WI160 EWT Services (2%)" },
      { value: "WI011_10", label: "WI011 EWT Professionals (10%)" },
    ]

    const response = await getSingleProjectPI(id)
    if (response.data) {
      var data = response.data.data[0]
      data.invoice_no = data.invoice_no?.map((invoice) => {
        return invoice.invoice_no ? invoice.invoice_no : "N/A"
      })
      data.invoice_id = data.invoice_no?.map((invoice) => {
        return invoice.id ? invoice.id : ""
      })

      var items = data.project_invoice_items.map((item) => {
        var info = item
        var vatInAdded = parseFloat(item.vat_in) - parseFloat(item.price)
        info.qty = parseInt(item.qty)
        info.wht_percent =
          data.vat_in_type === "goods"
            ? whtOptionsGoods.find(
                (option) =>
                  item.wht_percent && option.value.includes(item.wht_percent)
              )?.label || "0%"
            : whtOptionsService.find(
                (option) =>
                  item.wht_percent && option.value.includes(item.wht_percent)
              )?.label || "0%"
        info.received_qty = item.received_qty ? parseInt(item.received_qty) : 0
        info.remaining_qty = item.remaining_qty
          ? parseInt(item.remaining_qty)
          : 0
        info.current_qty = item.current_qty ? parseInt(item.current_qty) : 0
        info.unit = item.unit
        info.price = `PHP ${formatAmount(item.price)}`
        info.grand_total = `PHP ${formatAmount(item.grand_total)}`
        info.vat_in = `PHP ${formatAmount(item.vat_in)}`
        info.wht_amount = `PHP ${formatAmount(item.wht_amount)}`
        info.vat_in_added_amount = formatAmount(vatInAdded)
        info.remarks = item.remarks || "-"
        info.last_received_date = item.last_received_date
          ? formatDateSlash(item.last_received_date)
          : "--/--/---"
        info.last_received_by = item.last_received_by
          ? item.last_received_by
          : "N/A"
        return info
      })

      const totalPrice = items.reduce((sum, item) => {
        // Remove 'PHP ' prefix and commas, then convert to number
        const numericPrice = parseFloat(
          item.price.replace("PHP ", "").replace(/,/g, "")
        )
        return sum + numericPrice
      }, 0)

      const totalVatIn = items.reduce((sum, item) => {
        // Remove 'PHP ' prefix and commas, then convert to number
        const numericPrice = parseFloat(
          item.vat_in.replace("PHP ", "").replace(/,/g, "")
        )
        return sum + numericPrice
      }, 0)

      data.twelve_percent_vat = totalVatIn - totalPrice
      setReviewSE(data)
      setItems(items)
    } else if (response.error) {
      TokenExpiry(response)
    }
  }

  const confirmStatusChange = (status) => {
    let newStatus = status
    if (status === "pending" && getType() === "admin") {
      newStatus = "finalized"
    } else if (status === "pending" && getType() === "finance_2") {
      newStatus = "approved"
    }

    modal.confirm({
      title: `Are you sure you want to mark this purchase invoice ${status}?`,
      icon: <CheckCircleOutlined />,
      okText: "Proceed",
      cancelText: "Cancel",
      onOk: () => submitApproveSE(newStatus),
    })
  }

  async function handleSendInvoice() {
    if (pdfRef.current) {
      setSaving(true)
      const element = pdfRef.current
      html2canvas(element, { scale: 1.5 }).then(async (canvas) => {
        const imgData = canvas.toDataURL("image/jpeg", 0.7)
        const imgWidth = 210
        const imgHeight = (canvas.height * imgWidth) / canvas.width
        const pdf = new jsPDF("", "mm", "a4")
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight)
        const pdfBlob = pdf.output("blob")
        const pdfFile = new File([pdfBlob], `Purchase_Invoice_${id}.pdf`, {
          type: "application/pdf",
        })
        // saveAs(pdfFile, `Purchase_Invoice_${id}.pdf`)

        const payload = {
          project_invoice_id: id,
          attachment: pdfFile,
        }
        const formValues = Object.entries(payload).map(([key, value]) => ({
          key,
          value,
        }))
        const response = await UsePostRequest(
          "project_invoices/send_to_client",
          formValues,
          true
        )
        if (response.data) {
          toast.success(response.data.response, { style: toastStyle() })
          setTimeout(() => navigate("/projectpurchaseinvoice"), 500)
        } else {
          setSaving(false)
          toast.error("Error sending invoice", { style: toastStyle() })
        }
      })
    }
  }

  const headersIncompleteOrComplete = [
    // "Item",
    // "Qty",
    // "Unit",
    "Price",
    // "Dsc",
    "VAT",
    "WHT (%)",
    "WHT Amt",
    "Grand Total",
    "Account Type",
    // "Remarks",
  ]

  const headersNonVat = [
    // "Item",
    // "Qty",
    // "Unit",
    "Price",
    // "Dsc",
    // "WHT %",
    // "WHT Amount",
    "Grand Total",
    "Account Type",
    "Project Name",
    "Class",
    // "Remarks",
  ]

  const selectorsNonVat = [
    // "name",
    // "qty",
    // "unit",
    "price",
    // "discount",
    // "wht_percent",
    // "wht_amount",
    "grand_total",
    "expense_type_name",
    "project_ce_name",
    // "ce_no_name",
    "class_name",
    // "remarks",
  ]

  const selectorsIncompleteOrComplete = [
    // "name",
    // "qty",
    // "unit",
    "price",
    // "discount",
    "vat_in_added_amount",
    "wht_percent",
    "wht_amount",
    "grand_total",
    "expense_type_name",
    "ce_no_name",
    "class_name",
    // "remarks",
  ]

  const headers = [
    // "Item",
    // "Qty",
    // "Unit",
    "Price",
    // "Dsc",
    "VAT",
    "WHT (%)",
    "WHT Amt",
    "Grand Total",
    "Account Type",
    "Project Name",
    "Class",
    // "Remarks",
  ]

  const selectors = [
    // "name",
    // "qty",
    // "unit",
    "price",
    // "discount",
    "vat_in_added_amount",
    "wht_percent",
    "wht_amount",
    "grand_total",
    "expense_type_name",
    "project_ce_name",
    "class_name",
    // "remarks",
  ]

  const vatExHeaders = [
    "Price",
    "WHT (%)",
    "WHT Amt",
    "Grand Total",
    "Account Type",
    "Project Name",
    "Class",
    // "Remarks",
  ]

  const vatExSelectors = [
    "price",
    "wht_percent",
    "wht_amount",
    "grand_total",
    "expense_type_name",
    "project_ce_name",
    "class_name",
    // "remarks",
  ]

  function renderTable() {
    return (
      <>
        <Table>
          <thead>
            <tr>
              {reviewSE.vat_type === "zero_rated" ? (
                <>
                  {headersNonVat.map((header) => {
                    return (
                      <th
                        style={{
                          textAlign: [
                            "Price",
                            "VAT",
                            "WHT (%)",
                            "WHT Amt",
                            "Grand Total",
                          ].includes(header)
                            ? "right"
                            : "left",
                        }}
                      >
                        {header}
                      </th>
                    )
                  })}
                </>
              ) : reviewSE.order_status === "incomplete" ||
                reviewSE.order_status === "complete" ? (
                <>
                  {headersIncompleteOrComplete.map((header) => {
                    return (
                      <th
                        style={{
                          textAlign: [
                            "Price",
                            "VAT",
                            "WHT (%)",
                            "WHT Amt",
                            "Grand Total",
                          ].includes(header)
                            ? "right"
                            : "left",
                        }}
                      >
                        {header}
                      </th>
                    )
                  })}
                </>
              ) : reviewSE.vat_type === "vat_ex" ? (
                <>
                  {vatExHeaders.map((header) => {
                    return (
                      <th
                        style={{
                          textAlign: [
                            "Price",
                            "VAT",
                            "WHT (%)",
                            "WHT Amt",
                            "Grand Total",
                          ].includes(header)
                            ? "right"
                            : "left",
                        }}
                      >
                        {header}
                      </th>
                    )
                  })}
                </>
              ) : (
                <>
                  {headers.map((header) => {
                    return (
                      <th
                        style={{
                          textAlign: [
                            "Price",
                            "VAT",
                            "WHT (%)",
                            "WHT Amt",
                            "Grand Total",
                          ].includes(header)
                            ? "right"
                            : "left",
                        }}
                      >
                        {header}
                      </th>
                    )
                  })}
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {items.map((item) => {
              return reviewSE.vat_type === "zero_rated" ? (
                <tr key={item.id}>
                  {selectorsNonVat.map((selector) => {
                    return (
                      <td
                        style={{
                          textAlign: [
                            "price",
                            "wht_percent",
                            "wht_amount",
                            "grand_total",
                            "vat_in_added_amount",
                          ].includes(selector)
                            ? "right"
                            : "left",
                        }}
                      >
                        {item[selector]}
                      </td>
                    )
                  })}
                </tr>
              ) : reviewSE.order_status === "incomplete" ||
                reviewSE.order_status === "complete" ? (
                <tr key={item.id}>
                  {selectorsIncompleteOrComplete.map((selector) => {
                    return (
                      <td
                        style={{
                          textAlign: [
                            "price",
                            "wht_percent",
                            "wht_amount",
                            "grand_total",
                            "vat_in_added_amount",
                          ].includes(selector)
                            ? "right"
                            : "left",
                        }}
                      >
                        {item[selector]}
                      </td>
                    )
                  })}
                </tr>
              ) : reviewSE.vat_type === "vat_ex" ? (
                <tr key={item.id}>
                  {vatExSelectors.map((selector) => {
                    return (
                      <td
                        style={{
                          textAlign: [
                            "price",
                            "wht_percent",
                            "wht_amount",
                            "grand_total",
                            "vat_in_added_amount",
                          ].includes(selector)
                            ? "right"
                            : "left",
                        }}
                      >
                        {item[selector]}
                      </td>
                    )
                  })}
                </tr>
              ) : (
                <tr key={item.id}>
                  {selectors.map((selector) => {
                    return (
                      <td
                        style={{
                          textAlign: [
                            "price",
                            "wht_percent",
                            "wht_amount",
                            "grand_total",
                            "vat_in_added_amount",
                          ].includes(selector)
                            ? "right"
                            : "left",
                        }}
                      >
                        {item[selector]}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </>
    )
  }

  async function submitApproveSE(newStatus) {
    const response = await changeProjectPiStatus([id], newStatus)
    var text
    if (newStatus === "pending") {
      text = "Returned to Pending"
    } else {
      text = newStatus
    }

    if (response.data) {
      toast.success("Project Invoice " + text + " Successfully", {
        style: toastStyle(),
      })
      setTimeout(() => navigate("/projectpurchaseinvoice"), 500)
    } else if (response.error) {
      toast.error("Error Changing Status for Project Invoice No. " + id, {
        style: toastStyle(),
      })
    }
  }

  function renderButtons() {
    switch (reviewSE.status) {
      case "pending":
        return (
          <>
            <button
              type="button"
              className="button-secondary me-3"
              onClick={() => navigate("/projectpurchaseinvoice")}
            >
              Close
            </button>
            {getType() !== "business_unit_head" &&
              getType() !== "business_unit_director" && (
                <button
                  type="button"
                  className="button-tertiary me-3"
                  onClick={() =>
                    navigate("/projectpurchaseinvoice/edit/reviewed/" + id)
                  }
                >
                  Edit
                </button>
              )}

            <button
              type="button"
              className="button-primary me-3"
              onClick={() => confirmStatusChange("reviewed")}
              disabled={
                (getType() === "business_unit_director" &&
                  reviewSE.reviewed_by_bu_director) ||
                (getType() === "business_unit_head" &&
                  !reviewSE.reviewed_by_bu_director) ||
                (getType() !== "business_unit_director" &&
                  getType() !== "business_unit_head")
                // && getType() !== "admin"
              }
            >
              Mark Reviewed
            </button>
          </>
        )
      case "reviewed":
        return (
          <>
            <button
              type="button"
              className="button-secondary me-3"
              onClick={() => navigate("/projectpurchaseinvoice")}
            >
              Close
            </button>
            <button
              type="button"
              className="button-primary me-3"
              onClick={() => confirmStatusChange("approved")}
              disabled={
                getType() !== "admin" &&
                getType() !== "business_unit_finance_head"
              }
            >
              Approve
            </button>
            <button
              type="button"
              className="button-warning me-3"
              onClick={() => confirmStatusChange("disapproved")}
              disabled={
                getType() !== "admin" &&
                getType() !== "business_unit_finance_head"
              }
            >
              Disapprove
            </button>
          </>
        )
      case "approved":
        return (
          <>
            <button
              type="button"
              className="button-secondary me-3"
              onClick={() => navigate("/projectpurchaseinvoice")}
            >
              Close
            </button>
            <button
              type="button"
              className="button-primary me-3"
              onClick={() => confirmStatusChange("finalized")}
              disabled={getType() !== "admin"}
            >
              Mark Finalized
            </button>
            <button
              type="button"
              className="button-warning-fill me-3"
              onClick={() => confirmStatusChange("rejected")}
              disabled={getType() !== "admin"}
            >
              Mark As Rejected
            </button>
          </>
        )
      case "finalized":
        return (
          <>
            <button
              type="button"
              className="button-secondary me-3"
              onClick={() => navigate("/projectpurchaseinvoice")}
            >
              Close
            </button>
            <button
              type="button"
              className="button-primary me-3"
              // onClick={() => confirmStatusChange("sent")}
              onClick={handleSendInvoice}
              disabled={getType() !== "admin" || saving}
            >
              SEND
            </button>
          </>
        )
      case "rejected":
        return (
          <>
            <button
              type="button"
              className="button-secondary me-3"
              onClick={() => navigate("/projectpurchaseinvoice")}
            >
              Close
            </button>
            {/* <button
              type="button"
              className="button-primary me-3"
              onClick={() => confirmStatusChange("finalized")}
              disabled={getType() !== "admin"}
            >
              Mark As Finalized
            </button> */}
          </>
        )
      default:
        return (
          <>
            <button
              type="button"
              className="button-secondary me-3"
              onClick={() => navigate("/projectpurchaseinvoice")}
            >
              Close
            </button>
          </>
        )
    }
  }

  useEffect(() => {
    fetchSingleOperationalExpense()
  }, [])

  return (
    <div className="review-pinv">
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"PROJECT EXPENSES"}
        />
      </div>
      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <div className="d-flex justify-content-between">
          <h1 className="page-title mb-4">REVIEW PURCHASE INVOICE</h1>
          <div className="review-po">
            <span className="pe-5">PURCHASE INVOICE NO.</span>
            <span>{id}</span>
          </div>
        </div>

        {/* content */}
        <div className="review-form mb-3">
          <Row className="review-container py-3">
            <Row className="d-flex align-items-md-start">
              <Col sm={3}>
                <span className="review-label">VAT TYPE</span>
                <br />
                <span className="review-data text-uppercase">
                  {capitalizeFirstLetterOfEachWord(reviewSE.vat_type)}
                </span>
              </Col>
              {reviewSE.vat_type !== "zero_rated" && (
                <Col sm={3}>
                  <span className="review-label">TYPE</span>
                  <br />
                  <span className="review-data text-uppercase">
                    {reviewSE.vat_in_type}
                  </span>
                </Col>
              )}

              <Col sm={3}>
                <span className="review-label">Due Date</span>
                <br />
                <span className="review-data">
                  {dateFormat(reviewSE.due_date)}
                </span>
              </Col>
              <Col sm={3}>
                <span className="review-label">Business Unit</span>
                <br />
                <span className="review-data">{reviewSE.distributor_name}</span>
              </Col>
            </Row>
            <Row className="d-flex align-items-start mt-4">
              <Col sm={3}>
                <span className="review-label">Supplier Name</span>
                <br />
                <span className="review-data">
                  {reviewSE.supplier_trade_name ||
                    reviewSE.vendor_trade_name ||
                    "N/A"}
                </span>
              </Col>
              <Col sm={3}>
                <span className="review-label">Request Date</span>
                <br />
                <span className="review-data">
                  {dateFormat(reviewSE.request_date)}
                </span>
              </Col>
              <Col sm={3}>
                <span className="review-label">Remarks</span>
                <br />
                <span className="review-data">{reviewSE.remark}</span>
              </Col>
            </Row>
            {/* <Row className="d-flex align-items-start mt-4">
              <Col xs={12} lg={12}>
                <span className="review-label">File Attachment/s</span>
                <br />
                {reviewSE.project_invoice_attachment?.map((file, index) => (
                  <>
                    <span className="review-data p-2 mt-2" key={index}>
                      <AttachFile />
                      {file.name}
                    </span>
                    <br />
                  </>
                ))}
              </Col>
            </Row> */}
            {/* <Row className="d-flex align-items-start mt-4">
              <Col xs={12} lg={12}>
                <span className="review-label">File Attachment/s</span>
                <br />
                {reviewSE.project_invoice_attachment?.map((file, index) => (
                  <div key={index} className="review-data p-2 mt-2">
                    <AttachFile />
                    <a
                      href={`${baseUrl}${file.file_url}`} // Assuming `file.url` contains the file link
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ms-2 text-primary text-decoration-none"
                    >
                      {file.name}
                    </a>
                  </div>
                ))}
              </Col>
            </Row> */}
            <Row className="d-flex align-items-start mt-4">
              <Col xs={12} lg={12}>
                <span className="review-label">File Attachment/s</span>
                <br />
                {reviewSE.project_invoice_attachment?.map((file, index) => {
                  return (
                    <div key={index} className="review-data p-2 mt-2">
                      <AttachFile />
                      <a
                        href={file.base_64}
                        download={file.name} // Allows users to download the file
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ms-2 text-primary text-decoration-none"
                      >
                        {file.name}
                      </a>
                    </div>
                  )
                })}
              </Col>
            </Row>
          </Row>

          <div className="mt-4 d-flex flex-column">
            <span className="review-data mb-2">PURCHASED ITEMS</span>
            <div className="review-purchased-items overflow-scroll">
              {renderTable()}
            </div>
            <Row className="align-right pt-3 me-3">
              <Col xs={2} className="text-end">
                <span className="edit-label color-gray">SUBTOTAL</span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="edit-label vat-total-text align-middle">
                  PHP
                </span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="edit-label align-middle vat-total-text text-end">
                  {formatAmount(reviewSE.subtotal)}
                </span>
              </Col>
            </Row>
            {reviewSE.vat_type === "vat_in" && (
              <Row className="align-right pt-1 me-3">
                <Col xs={2} className="text-end">
                  <span className="edit-label color-gray">12% VAT</span>
                </Col>
                <Col xs={1} className="text-end">
                  <span className="edit-label vat-total-text align-middle">
                    PHP
                  </span>
                </Col>
                <Col xs={1} className="text-end">
                  <span className="edit-label align-middle vat-total-text text-end">
                    {formatAmount(reviewSE.twelve_percent_vat)}
                  </span>
                </Col>
              </Row>
            )}

            {/* <Row className="align-right pt-1 me-3">
              <Col xs={2} className="text-end">
                <span className="edit-label color-gray">WHT %</span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="edit-label vat-total-text align-middle"></span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="edit-label align-middle vat-total-text text-end">
                  {reviewSE.wht_percent ?? 0}
                </span>
              </Col>
            </Row> */}
            {reviewSE.vat_type !== "zero_rated" && (
              <Row className="align-right pt-1 me-3">
                <Col xs={2} className="text-end">
                  <span className="edit-label color-gray">WHT AMOUNT</span>
                </Col>
                <Col xs={1} className="text-end">
                  <span className="edit-label vat-total-text align-middle">
                    PHP
                  </span>
                </Col>
                <Col xs={1} className="text-end">
                  <span className="edit-label align-middle vat-total-text text-end">
                    {formatAmount(reviewSE.wht_amount)}
                  </span>
                </Col>
              </Row>
            )}
            <Row className="align-right pt-1 me-3">
              <Col xs={2} className="text-end">
                <span className="edit-label color-gray">GRAND TOTAL</span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="edit-label vat-total-text align-middle">
                  PHP
                </span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="edit-label align-middle vat-total-text text-end">
                  {formatAmount(reviewSE.grand_total)}
                </span>
              </Col>
            </Row>
          </div>

          <div className="d-flex justify-content-end pt-5">
            {renderButtons()}
          </div>
        </div>
      </div>

      <div style={{ position: "absolute", left: "-99999px" }}>
        <div ref={pdfRef}>
          <PiPdfContent invoiceDetails={reviewSE} items={items} />
        </div>
      </div>
      {contextModal}
    </div>
  )
}
