import React, { useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { Button, Radio } from "antd"
import { PlusCircleOutlined } from "@ant-design/icons"
import { DeleteOutline } from "@mui/icons-material"
//components
import Table from "../../Components/TableTemplate/DataTable"
import Navbar from "../../Components/Navbar/Navbar"
import DeleteModal from "../../Components/Modals/DeleteModal"
import AddModal from "../../Components/Modals/AddModal"
import EditModal from "../../Components/Modals/EditModal"
import ViewModal from "../../Components/Modals/ViewModal"
import {
  createExpenseType,
  deleteExpenseType,
  editExpenseType,
  getAllExpenseType,
} from "../../Helpers/apiCalls/expensetypesApi"
import { toastStyle } from "../../Helpers/Utils/Common"
import toast from "react-hot-toast"

export default function ExpenseTypes() {
  const [inactive, setInactive] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const [showViewExpenseTypeModal, setShowViewExpenseTypeModal] =
    useState(false)
  const handleCloseViewExpenseTypeModal = () =>
    setShowViewExpenseTypeModal(false)

  // EDIT
  const [showEditExpenseTypeModal, setShowEditExpenseTypeModal] =
    useState(false)
  const handleShowEditExpenseTypeModal = () => setShowEditExpenseTypeModal(true)
  const handleCloseEditExpenseTypeModal = () =>
    setShowEditExpenseTypeModal(false)
  const [showAddExpenseTypeModal, setShowAddExpenseTypeModal] = useState(false)

  //API
  const [allData, setAllData] = useState([])
  // const [formValues, setFormValues] = useState({});
  const [selectedRow, setSelectedRow] = useState({})
  const [formValues, setFormValues] = useState({
    name: "",
    subtypes: [
      {
        name: "",
        subtypes: [],
      },
    ],
    description: "",
  })

  const categoryOptions = [
    { value: "assets", label: "Assets" },
    { value: "equity", label: "Equity" },
    { value: "expenses", label: "Expenses" },
    { value: "income", label: "Income" },
    { value: "liabilities", label: "Liabilities" },
    { value: "operating_expenses", label: "Operating Expenses" },
  ]

  //ONCHANGE
  const handleAddChange = (e) => {
    const { name, value } = e.target
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  //onEdit
  function handleOnEdit() {
    handleCloseViewExpenseTypeModal()
    handleShowEditExpenseTypeModal()
  }

  //DELETE or REMOVE FORWARDER
  function handleDeleteExpenseType() {
    removeExpenseType(selectedRow.id)
  }

  function formatSubtypes(subtypes, level = 0) {
    if (!subtypes || subtypes.length === 0) return null
    return (
      <ul className="m-2">
        {subtypes.map((subtype) => (
          <li key={subtype.id} className="mt-1">
            {subtype.name}
            {formatSubtypes(subtype.subtypes, level + 1)}
          </li>
        ))}
      </ul>
    )
  }

  function formatName(name) {
    const words = name.split("_")
    const capitalizedWords = words.map((word) => capitalizeFirstLetter(word))
    return capitalizedWords.join(" ")
  }

  //API CALL
  async function fetchAllExpenseType() {
    setShowLoader(true)
    const response = await getAllExpenseType()
    if (response.data) {
      const formattedData = response.data.map((expense) => ({
        ...expense,
        category_value: formatName(expense.category),
        formatted_subtypes: formatSubtypes(expense.expense_type_subtypes),
        expense_type_subtypes: expense.expense_type_subtypes.map((subtype) => ({
          ...subtype,
          name: capitalizeFirstLetter(subtype.name),
        })),
      }))
      setAllData(formattedData)
    } else {
      setAllData([])
    }
    setShowLoader(false)
  }

  function capitalizeFirstLetter(word) {
    if (!word) return "" // Handle empty strings or undefined input
    return word.charAt(0).toUpperCase() + word.slice(1)
  }

  async function handleSaveExpenseType() {
    setIsClicked(true)
    const existingExpenseType = allData.find(
      (expensetype) =>
        expensetype.name.toLowerCase() === formValues.name.toLowerCase()
    )

    if (existingExpenseType) {
      toast.error("Account type already exists!", {
        style: toastStyle(),
      })
      setIsClicked(false)
      return
    }
    const response = await createExpenseType(formValues)

    if (response.data) {
      toast.success("Successfully created account!", {
        style: toastStyle(),
      })

      setShowAddExpenseTypeModal(false)
      setFormValues({
        name: "",
        subtypes: [],
        description: "",
      })
      fetchAllExpenseType()
    } else {
      toast.error(response.error.data.messages.error, {
        style: toastStyle(),
      })
    }
    setIsClicked(false)
  }

  async function handleEditExpenseType() {
    setIsClicked(true)
    const response = await editExpenseType(formValues)
    if (response.data) {
      toast.success("Successfully updated account!", {
        style: toastStyle(),
      })
      handleCloseEditExpenseTypeModal()
      fetchAllExpenseType()
    } else {
      toast.error(response.error.data.messages.error, {
        style: toastStyle(),
      })
    }
    setIsClicked(false)
  }

  async function removeExpenseType(id) {
    const response = await deleteExpenseType(id)
    if (response.data) {
      toast.success("Successfully delete account!", {
        style: toastStyle(),
      })
      setShowDeleteModal(false)
      setShowViewExpenseTypeModal(false)
      fetchAllExpenseType()
    }
  }

  React.useEffect(() => {
    fetchAllExpenseType()
  }, [])

  const [submittableForm, setSubmittableForm] = useState(false)
  React.useEffect(() => {
    const requiredFieldKeys = ["name", "category"]
    let isValid = true

    //validation for subtypes
    // if (formValues.subtypes?.length > 0) {
    //   formValues.subtypes?.forEach((item) => {
    //     if (item.name?.trim() === "") {
    //       isValid = false
    //     }
    //     if (item.subtypes?.length > 0) {
    //       item.subtypes.forEach((item) => {
    //         if (!item.name || item.name === "" || item.name?.trim() === "") {
    //           isValid = false
    //         }
    //       })
    //     }
    //   })
    // }

    requiredFieldKeys.forEach((key) => {
      if (!formValues[key] || formValues[key]?.trim() === "") {
        isValid = false
      }
    })

    setSubmittableForm(isValid)
  }, [formValues])

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"CHART OF ACCOUNTS"}
        />
      </div>
      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <Row className="mb-4">
          <Col>
            <h1 className="page-title">CHART OF ACCOUNTS</h1>
          </Col>
          <Col className="d-flex justify-content-end">
            <button
              className="add-btn"
              onClick={() => setShowAddExpenseTypeModal(true)}
            >
              Add
            </button>
          </Col>
        </Row>
        <div className="tab-content">
          {/* TABLE */}
          <Table
            tableHeaders={["CATEGORY", "MAIN TYPE", "SUBTYPES"]}
            headerSelector={["category_value", "name", "formatted_subtypes"]}
            tableData={allData}
            showLoader={showLoader}
            handleRowClick={(row) => {
              setFormValues({ ...row, subtypes: row.expense_type_subtypes })
              setSelectedRow(row)
              setShowViewExpenseTypeModal(true)
            }}
            isPointer
            screen={"chart_of_account"}
          />
        </div>
      </div>

      {/* MODALS */}
      <DeleteModal
        text="chart of account"
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onDelete={handleDeleteExpenseType}
      />

      <AddModal
        title="ACCOUNT"
        size="lg"
        type="expensetype"
        show={showAddExpenseTypeModal}
        onHide={() => {
          setShowAddExpenseTypeModal(false)
          setFormValues({
            name: "",
            subtypes: [],
            description: "",
          })
        }}
        onSave={handleSaveExpenseType}
        isClicked={isClicked}
        data={formValues}
        disableSave={!submittableForm}
      >
        <div className="mt-3">
          <p className="nc-modal-custom-row m-0">
            {" "}
            CATEGORY
            <span className="required-icon">*</span>
          </p>
          <Row>
            <Radio.Group
              options={categoryOptions}
              value={formValues.category}
              onChange={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  category: e.target.value,
                }))
              }
            />
          </Row>
          <Row className="nc-modal-custom-row mt-3">
            <Col xs={6}>
              MAIN TYPE
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="name"
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
                required
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row mt-4">
            <Col xs={12}>
              SUBTYPE
              {formValues.subtypes?.map((item, parentIndex) => (
                <>
                  <div className="row mt-2">
                    <Col xs={8}>
                      <Form.Control
                        type="text"
                        className="nc-modal-custom-input mt-1"
                        onChange={(e) => {
                          const temp = [...formValues.subtypes]
                          temp[parentIndex].name = e.target.value
                          setFormValues((prev) => ({
                            ...prev,
                            subtypes: temp,
                          }))
                        }}
                        value={item.name}
                        required
                      />
                    </Col>
                    <Col xs={1}>
                      <DeleteOutline
                        className="cursor-pointer ms-3"
                        onClick={() => {
                          const temp = [...formValues.subtypes]
                          temp.splice(parentIndex, 1)
                          setFormValues((prev) => ({
                            ...prev,
                            subtypes: temp,
                          }))
                        }}
                      />
                    </Col>
                  </div>
                  {item.subtypes?.map((itemsubtype, childIndex) => (
                    <div className="row">
                      <Col xs={6} className="">
                        <Form.Control
                          type="text"
                          name="subtype"
                          className="nc-modal-custom-input mt-1"
                          onChange={(e) => {
                            const parent = [...formValues.subtypes]
                            const child = parent[parentIndex]
                            child["subtypes"][childIndex]["name"] =
                              e.target.value
                            setFormValues((prev) => ({
                              ...prev,
                              subtypes: parent,
                            }))
                          }}
                          value={itemsubtype.name}
                          required
                        />
                      </Col>
                      <Col xs={1}>
                        <DeleteOutline
                          className="cursor-pointer"
                          onClick={() => {
                            const parent = [...formValues.subtypes]
                            const child = parent[parentIndex].subtypes
                            child.splice(childIndex, 1)
                            setFormValues((prev) => ({
                              ...prev,
                              subtypes: parent,
                            }))
                          }}
                        />
                      </Col>
                    </div>
                  ))}
                  <Col>
                    <Button
                      className="mt-1"
                      type="text"
                      icon={<PlusCircleOutlined />}
                      onClick={() => {
                        const parent = [...formValues.subtypes]
                        const child = parent[parentIndex]
                        child["subtypes"].push({})
                        setFormValues((prev) => ({
                          ...prev,
                          subtypes: parent,
                        }))
                      }}
                    >
                      Add Sub-subtype
                    </Button>
                  </Col>
                </>
              ))}
              <br />
              <Button
                type="primary"
                className="mt-2"
                onClick={() => {
                  setFormValues((prev) => ({
                    ...prev,
                    subtypes: Array.isArray(prev.subtypes)
                      ? [...prev.subtypes, { name: "", subtypes: [] }]
                      : [""],
                  }))
                }}
              >
                Add Subtype
              </Button>
            </Col>
          </Row>
        </div>
      </AddModal>

      <EditModal
        title="ACCOUNT"
        size="lg"
        type="forwarder"
        show={showEditExpenseTypeModal}
        onHide={() => {
          setShowEditExpenseTypeModal(false)
          setFormValues({
            name: "",
            subtypes: [],
            description: "",
          })
        }}
        onSave={handleEditExpenseType}
        data={formValues}
        disableSave={!submittableForm}
        isClicked={isClicked}
      >
        <div className="mt-3">
          <p className="nc-modal-custom-row m-0">
            CATEGORY
            <span className="required-icon">*</span>
          </p>
          <Row>
            <Radio.Group
              options={categoryOptions}
              value={formValues.category}
              onChange={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  category: e.target.value,
                }))
              }
            />
          </Row>
          <Row className="nc-modal-custom-row mt-3">
            <Col xs={6}>
              MAIN TYPE
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="name"
                value={formValues.name}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleAddChange(e)}
                required
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row mt-4">
            <Col xs={12}>
              SUBTYPE
              {formValues.subtypes?.map((item, parentIndex) => (
                <>
                  <div className="row mt-2">
                    <Col xs={8}>
                      <Form.Control
                        type="text"
                        className="nc-modal-custom-input mt-1"
                        onChange={(e) => {
                          const temp = [...formValues.subtypes]
                          temp[parentIndex].name = e.target.value
                          setFormValues((prev) => ({
                            ...prev,
                            subtypes: temp,
                          }))
                        }}
                        value={item.name}
                        required
                      />
                    </Col>
                    <Col xs={1}>
                      <DeleteOutline
                        className="cursor-pointer"
                        onClick={() => {
                          const temp = [...formValues.subtypes]
                          temp.splice(parentIndex, 1)
                          setFormValues((prev) => ({
                            ...prev,
                            subtypes: temp,
                          }))
                        }}
                      />
                    </Col>
                  </div>
                  {item.subtypes?.map((itemsubtype, childIndex) => (
                    <div className="row">
                      <Col xs={6} className="">
                        <Form.Control
                          type="text"
                          name="subtype"
                          className="nc-modal-custom-input mt-1"
                          onChange={(e) => {
                            const parent = [...formValues.subtypes]
                            const child = parent[parentIndex]
                            child["subtypes"][childIndex]["name"] =
                              e.target.value
                            setFormValues((prev) => ({
                              ...prev,
                              subtypes: parent,
                            }))
                          }}
                          value={itemsubtype.name}
                          required
                        />
                      </Col>
                      <Col xs={1}>
                        <DeleteOutline
                          className="cursor-pointer"
                          onClick={() => {
                            const parent = [...formValues.subtypes]
                            const child = parent[parentIndex].subtypes
                            child.splice(childIndex, 1)
                            setFormValues((prev) => ({
                              ...prev,
                              subtypes: parent,
                            }))
                          }}
                        />
                      </Col>
                    </div>
                  ))}
                  <Col>
                    <Button
                      className="mt-1"
                      type="text"
                      icon={<PlusCircleOutlined />}
                      onClick={() => {
                        const parent = [...formValues.subtypes]
                        const child = parent[parentIndex]
                        if (child.subtypes) {
                          child["subtypes"].push({})
                        } else {
                          child["subtypes"] = [{}]
                        }

                        setFormValues((prev) => ({
                          ...prev,
                          subtypes: parent,
                        }))
                      }}
                    >
                      Add Sub-subtype
                    </Button>
                  </Col>
                </>
              ))}
              <br />
              <Button
                type="primary"
                className="mt-2"
                onClick={() => {
                  setFormValues((prev) => ({
                    ...prev,
                    subtypes: Array.isArray(prev.subtypes)
                      ? [...prev.subtypes, { name: "", subtypes: [] }]
                      : [""],
                  }))
                }}
              >
                Add Subtype
              </Button>
            </Col>
          </Row>
        </div>
      </EditModal>

      <ViewModal
        withButtons
        show={showViewExpenseTypeModal}
        onHide={() => {
          setShowViewExpenseTypeModal(false)
          setFormValues({
            name: "",
            subtypes: [],
            description: "",
          })
        }}
        onEdit={handleOnEdit}
        onDelete={() => setShowDeleteModal(true)}
        size="lg"
      >
        <div className="mt-1">
          <span className="custom-modal-body-title">ACCOUNT DETAILS</span>
          <p className="nc-modal-custom-row m-0 mt-2">CATEGORY</p>
          <Row>
            <Radio.Group
              options={categoryOptions}
              value={formValues.category}
              disabled
            />
          </Row>
          <Row className="nc-modal-custom-row mt-3">
            <Col>
              MAIN TYPE
              <Form.Control
                type="text"
                name="name"
                value={formValues.name}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row mt-3">
            <Col xs={12}>
              SUBTYPE
              {formValues.subtypes?.map((item) => (
                <>
                  <div className="row mt-2">
                    <Col xs={8}>
                      <Form.Control
                        type="text"
                        className="nc-modal-custom-input mt-1"
                        value={item.name}
                        disabled
                      />
                    </Col>
                  </div>
                  {item.subtypes?.map((itemsubtype) => (
                    <div className="row">
                      <Col xs={6} className="">
                        <Form.Control
                          type="text"
                          name="subtype"
                          className="nc-modal-custom-input mt-1 w-90"
                          value={itemsubtype.name}
                          disabled
                        />
                      </Col>
                    </div>
                  ))}
                </>
              ))}
              <br />
            </Col>
          </Row>
        </div>
      </ViewModal>
    </div>
  )
}
