import React, { useContext, useEffect, useState } from "react"
import Navbar from "../../Components/Navbar/Navbar"
import { DatePicker, Table } from "antd"
import { UseGetRequest } from "../../Helpers/hooks/GetRequests"
import toast from "react-hot-toast"
import { formatAmount, toastStyle } from "../../Helpers/Utils/Common"
import { Context } from "../../Helpers/Context/Context"
import { Col, Row } from "react-bootstrap"
import downloadIcon from "../../Assets/Images/download_icon.png"
import { UsePostRequest } from "../../Helpers/hooks/UsePostApiCall"
import dayjs from "dayjs"

const TrialBalance = () => {
  const { RangePicker } = DatePicker
  const [inactive, setInactive] = useState(true)
  const [data, setData] = useState([])
  const { primaryColor } = useContext(Context)
  const [showLoader, setShowLoader] = useState(false)
  const [exportToExcelLink, setExportToExcelLink] = useState("")
  const [dateFilter, setDateFilter] = useState({
    date_from: dayjs().startOf("month").format("YYYY-MM-DD"),
    date_to: dayjs().endOf("month").format("YYYY-MM-DD"),
  })
  const { selectedBuId } = useContext(Context)

  const tableColumns = [
    {
      title: "ACCOUNT TYPE",
      dataIndex: "name",
      align: "left",
      render: (text, record) => {
        const isCustom = text.toLowerCase().includes("total")
        return (
          <span
            style={{
              fontWeight: isCustom ? "bold" : "normal",
              textTransform: isCustom ? "uppercase" : "none",
              color: isCustom ? primaryColor : "",
            }}
          >
            {text}
          </span>
        )
      },
    },
    {
      title: "DEBIT",
      dataIndex: "debit",
      align: "right",
      render: (text, record) => {
        const isCustom = record.name.toLowerCase().includes("total")
        return (
          <span
            style={{
              fontWeight: isCustom ? "bold" : "normal",
              textTransform: isCustom ? "uppercase" : "none",
              color: isCustom ? primaryColor : "",
            }}
          >
            {text}
          </span>
        )
      },
    },
    {
      title: "CREDIT",
      dataIndex: "credit",
      align: "right",
      render: (text, record) => {
        const isCustom = record.name.toLowerCase().includes("total")
        return (
          <span
            style={{
              fontWeight: isCustom ? "bold" : "normal",
              textTransform: isCustom ? "uppercase" : "none",
              color: isCustom ? primaryColor : "",
            }}
          >
            {text}
          </span>
        )
      },
    },
  ]

  async function fetchData() {
    setShowLoader(true)
    const response = await UseGetRequest("reports/get_trial_balance", {
      date_from: dateFilter.date_from,
      date_to: dateFilter.date_to,
      business_unit_id: selectedBuId,
    })
    if (response.data) {
      const data = response.data.data.map((item) => {
        const info = item
        info.debit = formatAmount(parseFloat(item.debit).toFixed(2))
        info.credit = formatAmount(parseFloat(item.credit).toFixed(2))
        return info
      })
      setData(data)
      setData((prev) => [
        ...prev,
        {
          name: "Total",
          debit: formatAmount(parseFloat(response.data.debit_total).toFixed(2)),
          credit: formatAmount(
            parseFloat(response.data.credit_total).toFixed(2)
          ),
        },
      ])
      setShowLoader(false)
    } else {
      setShowLoader(false)
      toast.error(response.error?.data?.messages?.message, {
        style: toastStyle(),
      })
    }
  }

  async function exportToExcel() {
    const response = await UsePostRequest(
      "reports/create_trial_balance_excel",
      {
        date_from: dateFilter.date_from,
        date_to: dateFilter.date_to,
        business_unit_id: selectedBuId,
      },
      false
    )

    if (response.data) {
      setExportToExcelLink(response.data.url)
    } else {
      toast.error("Something went wrong", {
        style: toastStyle(),
      })
    }
  }

  useEffect(() => {
    fetchData()
    exportToExcel()
  }, [dateFilter])

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"FINANCIAL STATEMENTS"}
        />
      </div>
      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <div className="row">
          <h1 className="page-title mb-1 mt-3">TRIAL BALANCE</h1>
        </div>
        <Row className="d-flex flex-row justify-content-between align-items-center">
          <Col xs={12} md={6} lg={3} className="mt-2">
            <RangePicker
              defaultValue={[
                dayjs(dateFilter.date_from, "YYYY-MM-DD"),
                dayjs(dateFilter.date_to, "YYYY-MM-DD"),
              ]}
              onChange={(value, dateString) => {
                setDateFilter((prev) => ({
                  ...prev,
                  date_from: dateString[0],
                  date_to: dateString[1],
                }))
              }}
            />
            {/* <DatePicker
              style={{ width: 300 }}
              value={dayjs(dateFilter.date_from)}
              onChange={(e) => {
                if (e) {
                  setDateFilter((prev) => ({
                    ...prev,
                    date_from: e.format("YYYY-MM-DD"),
                  }))
                } else {
                  setDateFilter((prev) => ({
                    ...prev,
                    date_from: "",
                  }))
                }
              }}
            /> */}
          </Col>
          <Col xs={12} md={6} lg={2}>
            <div className="button-primary py-2 w-full text-center">
              <span className="me-2">
                <img width={20} height={20} src={downloadIcon} alt=""></img>
              </span>
              <a href={exportToExcelLink} className="pointer custom-anchor">
                Export To Excel
              </a>
            </div>
          </Col>
        </Row>
        <div className="bg-white mt-4 mb-5 table-container">
          <Table
            columns={tableColumns}
            dataSource={data}
            pagination={false}
            loading={showLoader}
          />
        </div>
      </div>
    </div>
  )
}

export default TrialBalance
