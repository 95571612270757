import React, { useContext, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { DatePicker, Table } from "antd"
import Navbar from "../../Components/Navbar/Navbar"
import { formatAmount } from "../../Helpers/Utils/Common"
import { UseGetRequest } from "../../Helpers/hooks/GetRequests"
import { Context } from "../../Helpers/Context/Context"
import dayjs from "dayjs"
import downloadIcon from "../../Assets/Images/download_icon.png"

export default function AccountsReceivable() {
  const { RangePicker } = DatePicker
  const [inactive, setInactive] = useState(true)
  const { selectedBuId } = useContext(Context)
  const [filterConfig, setFilterConfig] = useState({
    business_unit_id: selectedBuId,
    date_from: dayjs().startOf("month").format("YYYY-MM-DD"),
    date_to: dayjs().endOf("month").format("YYYY-MM-DD"),
  })
  const [showLoader, setShowLoader] = useState(false)
  const [tableData, setTableData] = useState([])

  const tableColumns = [
    {
      title: "Customer",
      key: "name",
      dataIndex: "name",
      align: "left",
    },
    {
      title: "Current",
      key: "current",
      dataIndex: "current",
      align: "right",
      render: (data) => <div>{formatAmount(data)}</div>,
    },
    {
      title: "1 to 30 days",
      key: "1-30_days",
      dataIndex: "1-30_days",
      align: "right",
      render: (data) => <div>{formatAmount(data)}</div>,
    },
    {
      title: "31 to 60 days",
      key: "31-60_days",
      dataIndex: "31-60_days",
      align: "right",
      render: (data) => <div>{formatAmount(data)}</div>,
    },
    {
      title: "61 to 90 days",
      key: "61-90_days",
      dataIndex: "61-90_days",
      align: "right",
      render: (data) => <div>{formatAmount(data)}</div>,
    },
    {
      title: "Above 90 days",
      key: "above_90_days",
      dataIndex: "above_90_days",
      align: "right",
      render: (data) => <div>{formatAmount(data)}</div>,
    },
    {
      title: "Total",
      key: "total",
      dataIndex: "total",
      align: "right",
      render: (data) => <div>{formatAmount(data)}</div>,
    },
  ]

  function exportToExcel() {
    // const formattedData = data.map((item) => [
    //   item.name,
    //   item.debit,
    //   item.credit,
    // ])
    // const date = `${moment(dateFilter[0]).format("MMM DD, YYYY")} to ${moment(
    //   dateFilter[1]
    // ).format("MMM DD, YYYY")}`
    // // Define the header and title rows
    // const title = [["TRIAL BALANCE"]]
    // const buNameRow = [[buName]]
    // const dateRow = [[date]]
    // const header = [["Accounts", "Debit", "Credit"]]
    // // Prepare the worksheet data
    // const ws_data = [
    //   ...title,
    //   ...buNameRow,
    //   ...dateRow,
    //   [], // Add an empty row between the date and the headers
    //   ...header,
    //   ...formattedData,
    // ]
    // const wb = XLSX.utils.book_new()
    // const ws = XLSX.utils.aoa_to_sheet(ws_data)
    // // Merge cells to display titles
    // ws["!merges"] = [
    //   { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }, // A1:C1 for "TRIAL BALANCE"
    //   { s: { r: 1, c: 0 }, e: { r: 1, c: 2 } }, // A2:C2 for business unit name
    //   { s: { r: 2, c: 0 }, e: { r: 2, c: 2 } }, // A3:C3 for date range
    // ]
    // // Add styles (optional)
    // ws["A1"].s = {
    //   alignment: { horizontal: "center", vertical: "center" },
    //   font: { bold: true, size: 14 },
    // }
    // ws["A2"].s = {
    //   alignment: { horizontal: "center", vertical: "center" },
    //   font: { bold: true, size: 12 },
    // }
    // ws["A3"].s = {
    //   alignment: { horizontal: "center", vertical: "center" },
    //   font: { bold: true, size: 12 },
    // }
    // ws["A5"].s = {
    //   alignment: { horizontal: "center", vertical: "center" },
    //   font: { bold: true, size: 12 },
    // }
    // // Adjust the starting point of data to A6
    // XLSX.utils.sheet_add_aoa(ws, header, { origin: "A5" })
    // XLSX.utils.sheet_add_aoa(ws, formattedData, { origin: "A6" })
    // XLSX.utils.book_append_sheet(wb, ws, "TrialBalance")
    // XLSX.writeFile(wb, "TrialBalance.xlsx")
  }

  async function fetchTableData() {
    setShowLoader(true)
    setTableData([])
    const response = await UseGetRequest(
      "reports/get_accounts_payable",
      filterConfig
    )
    if (response.data) {
      const result = response.data.customers?.map((customer) => {
        let rangecurrent = 0
        let range130 = 0
        let range3160 = 0
        let range6190 = 0
        let range90above = 0

        var info = {
          ...customer,
          key: `customer_${customer.id}`,
          name: customer.customer_name,
          children: customer.projects
            ? customer.projects.map((project) => {
                rangecurrent += parseFloat(project.current ?? 0)
                range130 += parseFloat(project["1-30_days"] ?? 0)
                range3160 += parseFloat(project["31-60_days"] ?? 0)
                range6190 += parseFloat(project["61-90_days"] ?? 0)
                range90above += parseFloat(project["above_90_days"] ?? 0)
                var projectInfo = {
                  ...project,
                  key: `project_${project.id}`,
                  name: project.project_name,
                  children: project.invoices
                    ? project.invoices.map((invoice) => {
                        var invoiceInfo = {
                          ...invoice,
                          key: `invoice_${invoice.id}`,
                          name: `Invoice No. ${invoice.invoice_no ?? ""}`,
                        }
                        return invoiceInfo
                      })
                    : [],
                }
                return projectInfo
              })
            : [],
          current: rangecurrent,
          ["1-30_days"]: range130,
          ["31-60_days"]: range3160,
          ["61-90_days"]: range6190,
          ["above_90_days"]: range90above,
        }
        return info
      })
      setTableData(result)
    }
    setShowLoader(false)
  }

  React.useEffect(() => {
    fetchTableData()
  }, [filterConfig])

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"FINANCIAL STATEMENTS"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <Row className="mb-1">
          <Col xs={6}>
            <h1 className="page-title">ACCOUNTS RECEIVABLE </h1>
          </Col>
        </Row>

        <div>
          <Row className="my-2 mt-2 px-2 d-flex justify-content-between">
            <Col xs={3} className="mt-3">
              <RangePicker
                defaultValue={[
                  dayjs(filterConfig.date_from, "YYYY-MM-DD"),
                  dayjs(filterConfig.date_to, "YYYY-MM-DD"),
                ]}
                onChange={(value, dateString) => {
                  setFilterConfig((prev) => ({
                    ...prev,
                    date_from: dateString[0],
                    date_to: dateString[1],
                  }))
                }}
              />
            </Col>
            <Col xs={12} md={6} lg={2}>
              <button
                className="button-primary w-full py-2"
                onClick={() => exportToExcel()}
              >
                <span className="me-2">
                  <img width={20} height={20} src={downloadIcon} alt=""></img>
                </span>
                Export To Excel
              </button>
            </Col>
          </Row>

          <div className="px-2 mt-3">
            <Table
              dataSource={tableData}
              columns={tableColumns}
              loading={showLoader}
              pagination={false}
            />
          </div>
          <div />
        </div>
      </div>
    </div>
  )
}
