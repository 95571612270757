import { SyncLoader } from "react-spinners"
import DataTable from "react-data-table-component"
import NoDataPrompt from "../NoDataPrompt/NoDataPrompt"
import "./Table.css"
import styled from "styled-components"
import { formatAmountInt, getType } from "../../Helpers/Utils/Common"

export default function HoverableTable({
  OngoingPOBadges,
  tableHeaders,
  headerSelector,
  tableData,
  ActionBtn,
  PendingBtn,
  ViewBtn,
  showLoader,
  withActionData,
  PayBtn,
  conditionalRowStyles,
  handleRowClick,
  isPointer = false,
  handleRowHover,
  onSelectedIdsChange,
  setTotalBalance,
  setSelectedRows,
  selectedBank,
  showBulkSelector,
  module,
  moduleTab,
}) {
  const widthMapping = {
    "-": "7vw",
    "*": "7vw",
    "PRINT TEMPLATE": "20vw",
    CLASSIFICATION: "20vw",
    USERNAME: "15vw",
    "FRANCHISE DATE": "10vw",
    ADDRESS: "15vw",
    FRANCHISEE: "15vw",
    "BRANCH NAME": "15vw",
    ACTIONS: "6vw",
    " ": "10vw",
    "ACCOUNT NO.": "15vw",
    "NO.": "2vw",
    SUPPLIER: "20vw",
    PAYEE: "20vw",
    "PROJECT NAME": "15vw",
    CUSTOMER: "15vw",
    "START DATE": "15vw",
    "CONTRACT DATE": "13vw",
    "START DATE - END DATE": "20vw",
    "BUSINESS UNIT": "15vw",
    DATE: "10vw",
    REMARKS: "15vw",
    "ADDED BY": "20vw",
    "CREATED BY": "20vw",
    "REVIEWED BY": "20vw",
    "APPROVED BY": "20vw",
    "FINALIZED BY": "20vw",
    "DISAPPROVED BY": "20vw",
    "SENT BY": "20vw",
    "SE NO.": "5vw",
    "REQUEST DATE": "10vw",
    "DUE DATE": "10vw",
  }

  const defaultWidth = "auto"

  // const StyledRow = styled.div`
  //   &:hover {
  //     background-color: #f5f5f5; /* Change the background color on hover */
  //     cursor: pointer; /* Change cursor to pointer */
  //   }
  // `
  const CustomRowComponent = ({
    data,
    headers,
    selectors,
    columnIndex,
    handleRowClick,
  }) => {
    return (
      <div
        onMouseEnter={() => {
          setTimeout(() => {
            if (headers[columnIndex] === "REMARKS") {
              handleRowHover(data)
            }
          }, 500)
        }}
        onClick={() => handleRowClick(data)}
      >
        <div className="table-cell">
          {selectors[columnIndex] ? data[selectors[columnIndex]] : null}
        </div>
      </div>
    )
  }

  const columns = tableHeaders.map((header, index) => {
    const commonProps = {
      name: header,
      selector: (row) => row[headerSelector[index]] || "",
      reorder: true,
      width: widthMapping[header] || defaultWidth,
    }

    switch (header) {
      case "-":
        return { ...commonProps, button: true, cell: ViewBtn, reorder: false }
      case "*":
        return { ...commonProps, button: true, cell: PayBtn, reorder: false }
      case "ACTIONS":
        return !withActionData
          ? { ...commonProps, button: true, cell: ActionBtn }
          : commonProps
      case " ":
        return { ...commonProps, button: true, cell: PendingBtn }
      case "PROJECT EXPENSE":
        return {
          ...commonProps,
          button: true,
          cell: OngoingPOBadges,
          footer: (tableData) => {
            const total = tableData.reduce(
              (acc, val) => acc + (val ? parseFloat(val.amount) : 0),
              0
            )
            return `Total: ${total.toFixed(2)}`
          },
        }
      case "USERNAME":
        return {
          ...commonProps,
          selector: (row) =>
            row[headerSelector[index]] !== null
              ? row[headerSelector[index]].toLowerCase()
              : "",
        }
      case "SUBTYPES":
        return {
          ...commonProps,
          selector: (row) =>
            row[headerSelector[index]].map((type) => (
              <li className="mt-1">{type.name}</li>
            )),
        }
      case "REVIEWED BY":
        return { ...commonProps, sortable: true }
      case "FRANCHISE DATE":
      case "ACCOUNT NO.":
      case "NO.":
        return { ...commonProps, sortable: true }
      case "BU HEAD":
      case "BU DIRECTOR":
        return {
          ...commonProps,
          sortable: true,
          center: true,
          right: false,
          left: false,
        }
      default:
        return header === "AMOUNT" ||
          header === "PAID AMOUNT" ||
          header === "OTHER FEES" ||
          header === "GRAND TOTAL" ||
          header === "RECEIVABLE" ||
          header === "CREDIT" ||
          header === "BALANCE" ||
          header === "TOTAL" ||
          header === "PACKAGE" ||
          header === "DOC. NO" ||
          header === "INVCE NO." ||
          header === "TOTAL SALES" ||
          header === "TOTAL PROFIT" ||
          header === "PRICE" ||
          header === "BUDGET" ||
          header === "ACTUAL EXPENSE" ||
          header === "TOTAL AMOUNT" ||
          header === "OTHER FEES" ||
          header === "INTERNAL BUDGET" ||
          header === "COST ESTIMATE" ||
          header === "ACTUAL COST"
          ? { ...commonProps, sortable: true, right: true, width: "10vw" }
          : commonProps
    }
  })

  const paginationComponentOptions = {
    rowsPerPageText: "",
    noRowsPerPage: true,
  }

  const customStyles = {
    rows: {
      style: {
        minHeight: "5.2vh",
      },
    },
  }

  // const customStyles = {
  //   rows: {
  //     style: {
  //       minHeight: "5.2vh",
  //     },
  //   },
  //   headCells: {
  //     style: (header) => {
  //       if (header.name === "BU HEAD" || header.name === "BU DIRECTOR") {
  //         return {
  //           justifyContent: "center",
  //           padding: "auto",
  //         };
  //       }

  //     },
  //   },
  // };

  const newColumns = columns.map((col, colIndex) => ({
    ...col,
    cell: (row) => (
      <CustomRowComponent
        data={row}
        headers={tableHeaders}
        selectors={headerSelector}
        onHover={handleRowHover}
        columnIndex={colIndex}
        handleRowClick={handleRowClick}
      />
    ),
  }))

  const handleSelectionChange = (state) => {
    const ids = state.selectedRows.map((row) => row.id)
    if (setTotalBalance) {
      const tempBalance = state.selectedRows.reduce(
        (a, b) => a + formatAmountInt(b.grand_total ?? 0),
        0
      )
      setTotalBalance(tempBalance)
    }
    if (setSelectedRows) {
      if (
        module === "PINV" &&
        moduleTab === "pending" &&
        getType() === "business_unit_head"
      ) {
        var filteredIds = state.selectedRows
          .filter((row) => row.reviewed_by_bu_director !== null)
          .map((row) => row.id)
        var filteredData = state.selectedRows
          .filter((row) => row.reviewed_by_bu_director !== null)
          .map((row) => row.id)
        onSelectedIdsChange(filteredIds)
        setSelectedRows(filteredData)
      } else {
        onSelectedIdsChange(ids)
        setSelectedRows(state.selectedRows)
      }
    }
  }

  return showLoader ? (
    <div className="d-flex justify-content-center my-5">
      <SyncLoader color="var(--primary-color)" size={15} />
    </div>
  ) : (
    <DataTable
      pagination
      striped
      fixedHeader
      fixedHeaderScrollHeight="80vh"
      columns={newColumns}
      data={tableData}
      customStyles={customStyles}
      paginationComponentOptions={paginationComponentOptions}
      noDataComponent={<NoDataPrompt />}
      conditionalRowStyles={conditionalRowStyles}
      onRowClicked={handleRowClick}
      className={isPointer ? "pointer" : ""}
      selectableRows={showBulkSelector}
      onSelectedRowsChange={handleSelectionChange}
    />
  )
}
