import React, { useContext, useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { DatePicker, Select, Table } from "antd"
import Navbar from "../../Components/Navbar/Navbar"
import { formatAmount, toastStyle } from "../../Helpers/Utils/Common"
import downloadIcon from "../../Assets/Images/download_icon.png"
import { UseGetRequest } from "../../Helpers/hooks/GetRequests"
import { Context } from "../../Helpers/Context/Context"
import dayjs from "dayjs"
import { UsePostRequest } from "../../Helpers/hooks/UsePostApiCall"
import toast from "react-hot-toast"

const { RangePicker } = DatePicker
export default function BalanceSheet() {
  const { selectedBuId, primaryColor } = useContext(Context)
  const [inactive, setInactive] = useState(true)
  const [exportToExcelLink, setExportToExcelLink] = useState("")
  const [filterConfig, setFilterConfig] = useState({
    date_from: dayjs().startOf("month").format("YYYY-MM-DD"),
    date_to: dayjs().endOf("month").format("YYYY-MM-DD"),
    business_unit_id: selectedBuId,
  })
  const [showLoader, setShowLoader] = useState(false)
  const [tableData, setTableData] = useState([])
  const [projects, setProjects] = useState([])
  const [classes, setClassess] = useState([])

  async function fetchTableData() {
    setShowLoader(true)
    setTableData([])

    const response = await UseGetRequest(
      "reports/get_balance_sheet",
      filterConfig
    )

    if (response.data) {
      const formattedData = response.data.account_types.map((asset) => {
        return {
          ...asset,
          key: asset.name,
          total: formatAmount(asset.total_amount?.toFixed(2)),
          children: asset?.children?.map((type) => {
            return {
              ...type,
              key: `${type.name}${type.id}`,
              total: formatAmount(type.total_amount?.toFixed(2)),
              children: type?.children?.map((subtype) => ({
                ...subtype,
                key: `${subtype.name}${subtype.id}`,
                total: !subtype.children
                  ? formatAmount(
                      subtype.total_amount
                        ? subtype.total_amount?.toFixed(2)
                        : "0.00"
                    )
                  : "",
                children: subtype?.children?.map((sub_subtype) => {
                  return {
                    ...sub_subtype,
                    key:
                      sub_subtype.id !== undefined
                        ? `${sub_subtype.name}_${sub_subtype.id}`
                        : `total_${subtype.name}`,
                    name:
                      sub_subtype.name === "total"
                        ? `TOTAL (${subtype.name})`
                        : sub_subtype.name,
                    total: formatAmount(sub_subtype?.total_amount?.toFixed(2)),
                  }
                }),
              })),
            }
          }),
        }
      })

      console.log(formattedData)
      var totalLiabilities = formattedData.find(
        (row) => row.name === "liabilities"
      )?.total_amount
      var totalEquity = formattedData.find(
        (row) => row.name === "equity"
      )?.total_amount
      const totalLiabilitiesAndEquity = totalLiabilities + totalEquity
      formattedData.push({
        key: "total_liabilities_equity",
        name: "Total Liabilities & Equity",
        total: formatAmount(totalLiabilitiesAndEquity.toFixed(2)),
      })

      setTableData(formattedData)
    }

    setShowLoader(false)
  }

  async function exportToExcel() {
    const response = await UsePostRequest(
      "reports/create_balance_sheet_excel",
      filterConfig,
      false
    )

    if (response.data) {
      // const link = document.createElement("a")
      // link.href = response.data.url
      // link.download = "Balance Sheet.xlsx"
      // document.body.appendChild(link)
      // link.click()
      // document.body.removeChild(link)
      setExportToExcelLink(response.data.url)
    } else {
      toast.error("Something went wrong", {
        style: toastStyle(),
      })
    }
  }

  const tableColumns = [
    {
      title: "Account Type",
      key: "name",
      dataIndex: "name",
      align: "left",
      render: (text, record) => {
        const isCustom = text.toLowerCase().includes("total")
        const isParents =
          text === "assets" || text === "liabilities" || text === "equity"

        const isTotalKey = record.key.toLowerCase().includes("total_")
        return (
          <span
            style={{
              fontWeight: isCustom ? "bold" : "normal",
              textTransform: isParents || isCustom ? "uppercase" : "none",
              color: isTotalKey && primaryColor,
            }}
          >
            {text}
          </span>
        )
      },
    },
    {
      title: "Total",
      key: "total",
      dataIndex: "total",
      align: "right",
      render: (text, record) => {
        const isCustom = record.name.toLowerCase().includes("total")
        // ||
        // record.key === "assets" ||
        // record.key === "liabilities" ||
        // record.key === "equity"
        return (
          <span
            style={{
              fontWeight: isCustom ? "bold" : "normal",
              color: isCustom && primaryColor,
            }}
          >
            {text}
          </span>
        )
      },
    },
  ]

  async function fetchProjects() {
    const response = await UseGetRequest(`projects/search`, {
      business_unit_id: selectedBuId,
    })
    if (response.data) {
      var data = response.data.data.map((project) => {
        return {
          label: project.name,
          value: project.id,
        }
      })
      setProjects(data)
    }
  }

  async function fetchClasses() {
    const response = await UseGetRequest(`cost_centers/get_all_cost_center`, {
      business_unit_id: selectedBuId,
    })
    if (response.data) {
      var data = response.data.data.map((project) => {
        return {
          label: project.name,
          value: project.id,
        }
      })
      setClassess(data)
    }
  }

  useEffect(() => {
    fetchTableData()
    exportToExcel()
  }, [filterConfig])

  useEffect(() => {
    fetchProjects()
    fetchClasses()
  }, [])

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"FINANCIAL STATEMENTS"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        {/* headers */}
        <Row className="mb-1">
          <Col xs={6}>
            <h1 className="page-title"> BALANCE SHEET </h1>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={10} className="mt-2 d-flex flex-row gap-3">
            <RangePicker
              defaultValue={[
                dayjs(filterConfig.date_from, "YYYY-MM-DD"),
                dayjs(filterConfig.date_to, "YYYY-MM-DD"),
              ]}
              onChange={(e) => {
                if (e) {
                  setFilterConfig((prev) => ({
                    ...prev,
                    date_from: e[0].format("YYYY-MM-DD"),
                    date_to: e[1].format("YYYY-MM-DD"),
                  }))
                } else {
                  setFilterConfig((prev) => ({
                    ...prev,
                    date_from: "",
                    date_to: "",
                  }))
                }
              }}
            />
            <Select
              showSearch
              filterOption={(input, option) =>
                option?.label.toLowerCase().includes(input.toLowerCase())
              }
              style={{ width: 250 }}
              options={projects}
              placeholder="Select project"
              onChange={(e) => {
                setFilterConfig((prev) => ({
                  ...prev,
                  project_id: e,
                }))
              }}
            />
            <Select
              showSearch
              filterOption={(input, option) =>
                option?.label.toLowerCase().includes(input.toLowerCase())
              }
              style={{ width: 250 }}
              options={classes}
              placeholder="Select class"
              onChange={(e) => {
                setFilterConfig((prev) => ({
                  ...prev,
                  class_id: e,
                }))
              }}
            />
          </Col>
          <Col className="d-flex justify-content-end" xs={12} md={6} lg={2}>
            <div className="button-primary py-2 w-full text-center">
              <span className="me-2">
                <img width={20} height={20} src={downloadIcon} alt=""></img>
              </span>
              <a href={exportToExcelLink} className="pointer custom-anchor">
                Export To Excel
              </a>
            </div>
          </Col>
        </Row>

        <div className="mt-4">
          <div className="below">
            <div className="px-2">
              <Table
                dataSource={tableData}
                columns={tableColumns}
                loading={showLoader}
                pagination={false}
              />
            </div>
          </div>
          <div className="mb-2" />
        </div>
      </div>
    </div>
  )
}
