import { getUser, getToken, refreshPage, getAPIKey } from "../Utils/Common"
import axios from "axios"
import qs from "qs"

// Set up a global response interceptor
// axios.interceptors.response.use(
//     (response) => {
//       return response;
//     },
//     async (error) => {
//       // Handle 401 error
//       if (error.response && error.response.status === 401) {
//         localStorage.clear();
//         toast.error("Unauthorized. Logging you out...", { autoClose: 5000 });
//         setTimeout(() => {
//           refreshPage();
//           window.location.href = "/";
//         }, 1000);
//       }

//       // Propagate the error to the calling code
//       return Promise.reject(error);
//     }
//   );

const postCall = async (url, data) => {
  const config = {
    headers: {
      "api-key": "daccfc89-ff47-4ce1-99bf-5ad2d8f57282",
      "user-key": getAPIKey(),
      "Content-Type": "application/json",
    },
  }
  try {
    const response = await axios.post(
      process.env.REACT_APP_LINK + url,
      qs.stringify(data),
      config
    )
    return response.data
  } catch (error) {
    throw error.response
  }
}

export const getCall = (url, parameters) => {
  const config = {
    headers: {
      "api-key": "daccfc89-ff47-4ce1-99bf-5ad2d8f57282",
      "user-key": getAPIKey(),
      "Content-Type": "application/json",
    },
    params: parameters,
  }

  return axios.get(process.env.REACT_APP_LINK + url, config)
}

export const UseGetRequest = async (url, filter) => {
  try {
    const response = await getCall(url, {
      requester: getUser(),
      token: getToken(),
      ...filter,
    })
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

// DON'T BE TRICKED, THIS MAY BE POST BUT THIS ACTUALLY IS A GET INSIDE A POST METHOD.
// BACKEDN tingz I have to conform to
export const UsePostRequest = async (url, payload) => {
  try {
    const response = await postCall(url, {
      requester: getUser(),
      token: getToken(),
      ...payload,
    })
    return { data: response.data || response.employees, response: response }
  } catch (error) {
    // return {error: error.response}
    return { error: error }
  }
}
